import {
  Container, Flex
} from "@chakra-ui/react";
import React from "react";
import NewsListItem from "./NewsListItem";
import NewsListItemHref from "../components/NewsListItemHref";
import pdf1 from "/static/files/press/CN_tokentus_03.08.2021_E.pdf";
import pdf2 from "/static/files/press/CN_tokentus_04.10.2021_E-1.pdf";
import pdf3 from "/static/files/press/TOK_CN_18.10.2021_E-1.pdf";
import pdf4 from "/static/files/press/TOK_BCB_25.01.2022_e.pdf";
import pdf5 from "/static/files/press/TOK_AdvisoryBoard_03.03.2022_e.pdf";
import pdf6 from "/static/files/press/TOK_PortfolioDay_10.03.2022_e.pdf";
import pdf7 from "/static/files/press/TOK_gumi_31.03.2022_e.pdf";
import pdf8 from "/static/files/press/TOK_Celsius_05.04.2022_E.pdf";
import pdf9 from "/static/files/press/TOK_Nayms_Regulation_17.05.2022_E.pdf";
import pdf10 from "/static/files/press/TOK_HV2022_06.07.2022_E.pdf";
import pdf11 from "/static/files/press/TOK_PM_14.07.2022_E.pdf";


function TriFeature() {
  return (
    <Container
    variant="layoutContainer"
    px={{ base: 0, lg: 10 }}
    py={{ base: 0, lg: 10 }}
  >
    <Flex
      flexWrap="wrap"
      flexDirection="column"
      justifyContent={"space-between"}
    >
      <NewsListItem
        datum={"14.07.2022"}
        textinhalt={
          "tokentus investment AG: Investment Celsius Network initiates voluntary Chapter 11 proceedings"
        }
        pdflink={pdf11}
      />
      <NewsListItem
        datum={"06.07.2022"}
        textinhalt={
          "tokentus investment AG: Press release on the Virtual Annual General Meeting 2022"
        }
        pdflink={pdf10}
      />
        <NewsListItemHref
          datum={"29.06.2022"}
          textinhalt={
            "tokentus investment AG participated in the Prior Capital Markets Conference at Egelsbach Airport"
          }
          actionlink={
            "https://www.linkedin.com/feed/update/urn:li:activity:6947921827436384256"
          }
        />
      
    </Flex>
  </Container>
  );
}

export default TriFeature;
